<script>

import { men_return , returnwater} from "@/api/my";
export default {
    name:'DividendHistory',
    components: {
        Header: () => import('components/base/Header'),
        Description: () => import('components/common/Description'),
        'mt-datetime-picker': app.Mint.DatetimePicker,
        'mt-button': app.Mint.Button,
        'mt-header': app.Mint.Header,
    },
    data() {
        return {
            time1:null,
            time2:null,
            list: null,
            list1: null,
            startTime: new Date(),
            endTime: new Date(),
            sheetVisible: false,
            toallength: "",
            smallSum: "",
            smallSum2: "",
            formateDatestartTime: "",
            formateDateendTime: "",
            shortcutDateValue:null,

            totalProfit:0,
            header: {
                mainTitle: getWord('kickback'),
            },
            descriptionShow:false,
        };
    },
    methods: {
        showDescription(){
            this.descriptionShow=true;
            this.$router.push({
                path:'/my/return_of_members/return_of_members',
                query:{
                    'descriptionShow':true
                }
            })
        },
        showStart: function() {
            this.$refs["startPicker"].open();
        },
        showEnd: function() {
            this.$refs["endPicker"].open();
        },
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        handleConfirmStart: function(date) {
            this.time1=Math.round(date.getTime()/1000);
            var year = this.startTime.getFullYear();
            var month = this.startTime.getMonth() + 1;
            var day = this.startTime.getDate();
            this.formateDatestartTime = year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
        },
        handleConfirmEnd: function(date) {
            this.time2=Math.round(date.getTime()/1000);
            var year = this.endTime.getFullYear();
            var month = this.endTime.getMonth() + 1;
            var day = this.endTime.getDate();
            this.formateDateendTime = year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
        },
        formatTen(num) {
            return num > 9 ? num + "" : "0" + num;
        },

        search() {
            this.list = [];
            this.list1 = [];
            this.commit();
        },

        commit() {

            men_return(this.time1,this.time2).then(result => {
                if (result.data.code == "SUCCESS") {
                    this.list = result.data.result;

                    if (this.list.length===0) {
                        this.list.push({
                            errorMsg:getWord('no_result_in_this_range')
                        })
                    }

                    this.list.forEach(item=>{
                        this.totalProfit+=Number(item.sum_actual);
                    })
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });

            returnwater(this.time1,this.time2).then(result => {
                if (result.data.code == "SUCCESS") {
                    this.list1 = result.data.result;

                    if (this.list1.length===0) {
                        this.list1.push({
                            errorMsg:getWord('no_result_in_this_range')
                        })
                    }
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        },

        getDay(date) {
            var today = date || new Date();
            var oYear = today.getFullYear();
            var oMoth = (today.getMonth() + 1).toString();
            var oDay = today.getDate().toString();;
            if (oMoth.length <= 1) oMoth = "0" + oMoth;
            if (oDay.length <= 1) oDay = "0" + oDay;
            return oYear + '-' + oMoth + '-' + oDay;
        },
        shortcutDate($event, period){
            var days=period||1;
            if (period) {
                days=period;
                this.time1=Math.round((new Date().getTime()-1000*60*60*24*days)/1000)
                this.formateDatestartTime=this.getDay( new Date(new Date().getTime()-1000*60*60*24*7) )
                this.time2=Math.round(new Date().getTime()/1000);
                this.search();
            }
            if ($event) {
                this.shortcutDateValue=$event.target.id
            }
            if (this.shortcutDateValue==='yesterday' || ($event && $event.target.id==='yesterday') ) {
                this.time1=Math.round((new Date().getTime()-1000*60*60*24*days)/1000)
                this.formateDatestartTime=this.getDay( new Date(new Date().getTime()-1000*60*60*24) )
                this.time2=Math.round(new Date().getTime()/1000)
            }else {
                days=7;
                this.time1=Math.round((new Date().getTime()-1000*60*60*24*days)/1000)
                this.formateDatestartTime=this.getDay( new Date(new Date().getTime()-1000*60*60*24*7) )
                this.time2=Math.round(new Date().getTime()/1000)
            }
        }
    },
    computed:{

    },
    created(){
        if (this.$route.query.descriptionShow) {
            this.descriptionShow=true;
        }
    },
    mounted() {
        this.formateDatestartTime=this.getDay( new Date(new Date().getTime()-1000*60*60*24) )
        this.formateDateendTime = this.getDay();
        this.shortcutDateValue='yesterday'
        this.shortcutDate();
        this.search();
        if (this.$refs.main) {
            this.CustomScroll(this.$refs.main.id);
        }        
    },
    beforeRouteUpdate(to,from,next){
        if (!to.query.descriptionShow) {
            this.descriptionShow=false;
        }
        next();
    },
};
</script>

<template>
    <section id="personal" :class="_TEMPLATE">
        <Description v-if="descriptionShow">
            <template slot="content">
                <h4>{{ getWord(['rebate', 'explain']) }}2</h4>
                <p>{{ getWord('rebate_rules') }}</p>
            </template>
        </Description>
        <template v-if="!descriptionShow">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="main" id="main" ref="main">
                <hgroup class="clearfix">                
                    <fieldset>
                        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                            <input type="radio" id="yesterday" name="date" checked @click='shortcutDate' />
                            <label for="yesterday">{{ getWord('yesterday') }}</label>
                            <input type="radio" id="one-week" name="date" @click='shortcutDate' />
                            <label for="one-week">{{ getWord('recent_week') }}</label>
                        </template>
                        <template v-if="_TEMPLATE==='template-3'">
                            <input type="radio" id="yesterday" name="date" checked @click='shortcutDate($event,1)' />
                            <label for="yesterday">{{ getWord('yesterday') }}</label>
                            <input type="radio" id="one-week" name="date" @click='shortcutDate($event,7)' />
                            <label for="one-week">{{ getWord('recent_week2') }}</label>
                            <input type="radio" id="two-week" name="date" @click='shortcutDate($event,14)' />
                            <label for="two-week">{{ getWord('recent_two_weeks') }}</label>
                        </template>
                    </fieldset>
                    <h4>{{ getWord('select_range') }}</h4>
                </hgroup>
                <div class="filter">
                    <fieldset @click="showStart">
                        <label>{{ getWord('start_from') }}</label>
                        <span class="select">{{formateDatestartTime}}</span>
                        <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                    </fieldset>
                    <fieldset @click="showEnd">
                        <label>{{ getWord('end_in') }}</label>
                        <span class="select">{{formateDateendTime}}</span>
                        <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                    </fieldset>                
                    <div class="submit">
                        <mt-button @click="search()" type="primary">{{ getWord('inquire') }}</mt-button>
                    </div>
                </div>
                <div class="dividend-mode1" v-if="list">
                    <h4 class="clearfix">
                        <a @click="showDescription">{{ getWord(['rebate', 'explain']) }}</a>
                        {{ getWord('rebate2') }}
                    </h4>
                    <table>
                        <tbody>
                            <tr>
                                <th width="25%">{{ getWord(['game', 'type']) }}</th>
                                <th width="25%">{{ getWord(['effective', 'betting']) }}</th>
                                <th width="25%">{{ getWord(['win_loss']) }}</th>
                                <th width="25%">{{ getWord('rebate') }}</th>
                            </tr>
                            <tr v-for="item in list" v-if="list && list[0] && !list[0].errorMsg">
                                <td>{{item.lottery_name}}</td>
                                <td>{{item.expenditure_count}}</td>
                                <td>{{(item.sum_expenditure-item.sum_income).toFixed(2)}}</td>
                                <td>{{item.sum_actual}}</td>
                            </tr>
                            <tr v-else>
                                <td colspan="4">{{item.errorMsg}}</td>
                            </tr>
                        </tbody>
                        <tfoot v-if="list && list[0] && !list[0].errorMsg">
                            <tr>
                                <td>{{ getWord('summary') }}</td>
                                <td></td>
                                <td></td>
                                <td>{{totalProfit}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="dividend-mode2" v-if="list1">
                    <h4>{{ getWord('kickback2') }}</h4>
                    <table>
                        <tbody>
                            <tr>
                                <th width="50%">{{ getWord(['effective', 'betting']) }}</th>
                                <th width="50%">{{ getWord('kickback') }}</th>
                            </tr>
                            <tr v-if="list1 && !list1[0]">
                                <td>{{list1.number_money}}</td>
                                <td>{{list1.money}}</td>
                            </tr>
                            <tr v-else>
                                <td class="center" colspan="2">{{list1[0].errorMsg}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <mt-datetime-picker ref="startPicker" type="date" v-model="startTime" :year-format="'{value} '+getWord('year')" :month-format="'{value} '+getWord('month')" :date-format="'{value} '+getWord('day')" @confirm="handleConfirmStart"></mt-datetime-picker>
            <mt-datetime-picker ref="endPicker" type="date" v-model="endTime" :year-format="'{value} '+getWord('year')" :month-format="'{value} '+getWord('month')" :date-format="'{value} '+getWord('day')" @confirm="handleConfirmEnd"></mt-datetime-picker>
        </template>
    </section>
</template>

<style scoped lang='scss' type="text/css">
#personal {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-3 {

        .main {

            hgroup {
                background-color: #ffffff;
                display: flex;
                flex-direction: column-reverse;
                padding:.25rem;
                margin-bottom: .2rem;

                fieldset {
                    padding:0;
                    margin:0;
                    float: none;

                    label {
                        border-color: transparent;
                        color: #5F646E;
                        background-color: #F5F5F9;
                        padding: .1rem .25rem;

                        &:nth-of-type(1) {
                            margin-left: 0;
                        }
                    }

                    input[type='radio'] {

                        &:checked+label {
                            color: #ffffff;                            
                            background-color: #3C8EFB;
                        }
                    }
                }

                h4 {
                    font-size: .28rem;
                    color: #B0B0B0;
                    font-weight: normal;
                    margin:0;
                    padding: 0;
                    margin-bottom: .35rem;
                }
            }

            .filter {
                display: none;
            }

            .dividend-mode1,
            .dividend-mode2 {
                background-color: #ffffff;
                margin-bottom: .2rem;

                h4 {
                    color: #B0B0B0;
                    font-weight: normal;
                    padding: .25rem;
                    margin: 0;
                    border-bottom: 1px solid #B0B0B0;
                }
            }

            .dividend-mode1 {

                h4 {

                    a {
                        float: right;
                        color: #EC2829;
                    }
                }
            }

            .dividend-mode2 {
                
                table {

                    th,td {
                        &:first-child {
                            text-align:center;
                            padding-left: .4rem;
                        }

                        &:last-child {
                            text-align:center;
                            padding-right: .4rem;
                        }
                    }
                }
            }

            table {

                th {
                    font-weight: normal;
                    color: #B0B0B0;
                    font-size: .28rem;
                }

                td {
                    border-color: #B0B0B0;
                }
            }
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        background-color: #EFEFEF;

        hgroup {

            h4 {
                font-size: .3rem;
                color: #999999;
                margin-left: .4rem;
                padding-top: .4rem;
                padding-bottom: .2rem;
            }

            fieldset {
                border: 0;
                float: right;
                margin-right: .4rem;
                padding-top: .33rem;
                display: flex;
                word-break: break-all;

                label {
                    font-size: .3rem;
                    padding:.05rem .1rem;
                    min-width: 1rem;
                    display: inline-block;
                    text-align: center;
                    border: 1px solid #bbbbbb;
                    border-radius: 4px;
                    margin-left: .25rem;
                }

                input[type='radio'] {
                    width: 0;
                    height: 0;
                    visibility: hidden;

                    &:checked+label {
                        background-color: #B1D8FF;
                    }
                }
            }
        }

        .filter {
            
            fieldset {
                border:0;
                background-color: #ffffff;
                border-top: 1px solid #cccccc;
                font-size: .3rem;
                height: .9rem;
                line-height: .9rem;
                position: relative;

                &:first-child {
                    border-top:0;
                }

                label {
                    color: #999999;
                    margin-left: .4rem;
                    width: 25%;
                    display: inline-block;
                }

                .select {

                }

                .rightArrow {
                    position: absolute;
                    right: 0.45rem;
                    top: 0.3rem;
                    width: 0.18rem;
                    height: 0.33rem;
                    filter: grayscale(100%);
                    opacity: .7;
                    transform: rotate(90deg);
                }
            }

            div.submit {
                padding:0 .4rem;
                margin:.4rem 0;

                button {
                    display: block;
                    width: 100%
                }
            }
        }

        .dividend-mode1 {

            h4 {
                font-size: .3rem;
                color: #999999;
                margin-left: .4rem;
                padding-bottom: .2rem;
            }
        }

        .dividend-mode2 {

            h4 {
                font-size: .3rem;
                color: #999999;
                margin-left: .4rem;
                padding-top: .4rem;
                padding-bottom: .2rem;
            }

            table {

                th,td {

                    &:first-child {
                        text-align:left;
                        padding-left: .4rem;
                    }

                    &:last-child {
                        text-align:right;
                        padding-right: .4rem;
                    }

                    &.center {
                        text-align: center;
                    }
                }
            }
        }

        table {
            border-collapse: collapse;
            background-color: white;
            font-size: 0.3rem;
            text-align: center;
            width: 100%;

            .red-text {
                color: red;
            }

            .green-text {
                color: green;
            }

            th {    
                color: rgba(71, 150, 252, 1);
                padding: .25rem 0;
                
                &:first-child {
                    border-left:0;
                }
            }

            td {
                border-top: 1px solid rgba(187, 187, 187, 1);
                font-size: 0.24rem;
                color: rgba(102, 102, 102, 1);
                padding: .25rem 0;
                
                &:first-child {
                    border-left:0;
                }
            }
        }
    }
}
</style>


